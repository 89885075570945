import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_ESCROW_DETAIL_BEGIN,
  FETCH_ESCROW_DETAIL_SUCCESS,
  FETCH_ESCROW_DETAIL_FAILURE,
} from "./constants";
import {
  escrowedRewardABI,
  contracts
} from "../../configure";

export function fetchEscrowDetail({ web3, address, data }) {    
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_ESCROW_DETAIL_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      let promises = [];

      for (let reward of contracts.escrowedRewards) {
        const escrowedContract = new web3.eth.Contract(
          escrowedRewardABI,
          reward.escrowPool
        );
        promises.push(escrowedContract.methods.getDepositsOf(address).call());
      }


      Promise.all(promises)
        .then(async (results) => {
          const output = {};
          let escrowedDatas = [];
          contracts.escrowedRewards.forEach(function (reward, j) {
            const escrowedData = {
              tokenName: reward.tokenName,
              tokenAddress: reward.tokenAddress,
              deposits: results[j]
            }
            escrowedDatas.push(escrowedData);
          });

          output["escrowedDatas"] = escrowedDatas;
          dispatch({
            type: FETCH_ESCROW_DETAIL_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          console.log('error', error)

          dispatch({
            type: FETCH_ESCROW_DETAIL_FAILURE,
          });
          return reject(error.message || error);
        });
    });

    return promise;
  };
}

export function useFetchEscrowDetail() {
  const dispatch = useDispatch();
  const { escrowDetails, fetchRewardsPending } = useSelector(
    (state) => ({
      fetchRewardsPending: state.reward.fetchRewardsPending,
      escrowDetails: state.reward.escrowDetails,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchEscrowDetail(data));
    },
    [dispatch]
  );

  return {
    escrowDetails,
    fetchEscrowDetail: boundAction,
    fetchRewardsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_ESCROW_DETAIL_BEGIN:
      return {
        ...state,
        fetchRewardsPending: true,
      };

    case FETCH_ESCROW_DETAIL_SUCCESS:
      return {
        ...state,
        escrowDetails: action.data,
        fetchRewardsPending: false,
      };

    case FETCH_ESCROW_DETAIL_FAILURE:
      return {
        ...state,
        fetchRewardsPending: false,
      };

    default:
      return state;
  }
}
