import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FETCH_CLAIM_ALL_BEGIN,
  FETCH_CLAIM_ALL_SUCCESS,
  FETCH_CLAIM_ALL_FAILURE,
} from "./constants";
import { enqueueSnackbar } from "features/common/redux/actions";
import { claimAll } from "features/web3";

export function fetchClaimAll({ address, web3, contractAddress}) {
  return (dispatch) => {
    dispatch({
      type: FETCH_CLAIM_ALL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      claimAll({ web3, address, contractAddress, dispatch })
        .then((data) => {
          dispatch({
            type: FETCH_CLAIM_ALL_SUCCESS,
            data,
          });
          // dispatch(fetchFarmPools());
          dispatch(
            enqueueSnackbar({
              key: new Date().getTime() + Math.random(),
              message: "Claim Success",
              options: {
                variant: "success",
              },
            })
          );
          resolve(data);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_CLAIM_ALL_FAILURE,
          });
          dispatch(
            enqueueSnackbar({
              message: error.message || error,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
              },
            })
          );
          reject(error.message || error);
        });
    });
    return promise;
  };
}

export function useFetchClaimAll() {
  // args: false value or array
  // if array, means args passed to the action creator
  const dispatch = useDispatch();

  const { fetchClaimAllPending } = useSelector((state) => ({
    fetchClaimAllPending: state.stake.fetchClaimAllPending,
  }));

  const boundAction = useCallback(
    (data) => dispatch(fetchClaimAll(data)),
    [dispatch]
  );

  return {
    fetchClaimAll: boundAction,
    fetchClaimAllPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_CLAIM_ALL_BEGIN:
      return {
        ...state,
        fetchClaimAllPending: true,
      };

    case FETCH_CLAIM_ALL_SUCCESS:
    case FETCH_CLAIM_ALL_FAILURE:
      return {
        ...state,
        fetchClaimAllPending: false,
      };

    default:
      return state;
  }
}
