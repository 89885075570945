import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  REWARD_POOLS_FETCH_BEGIN,
  REWARD_POOLS_FETCH_SUCCESS,
  REWARD_POOLS_FETCH_FAILURE,
} from "./constants";
import { MultiCall } from "eth-multicall";
import { erc20ABI, contracts } from "../../configure";

export function fetchRewardPoolsDetails() {
  return (dispatch, getState) => {
    dispatch({
      type: REWARD_POOLS_FETCH_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const { home } = getState();
      const { web3 } = home;
      const multicall = new MultiCall(web3, contracts.multicall.address);

      let calls = [];
      for (let reward of contracts.escrowedRewards) {
        const rewardTokenContract = new web3.eth.Contract(
          erc20ABI,
          reward.tokenAddress
        );
        calls.push({ result: rewardTokenContract.methods.balanceOf(reward.escrowPool) });
      }

      multicall
        .all([calls])
        .then(([results]) => {

          let output = [];
          contracts.escrowedRewards.forEach(function (reward, j) {
            const rewardPoolData = {
              tokenName: reward.tokenName,
              tokenAddress: reward.tokenAddress,
              balance: results[j].result
            }
            output[j] = rewardPoolData;
          });

          dispatch({
            type: REWARD_POOLS_FETCH_SUCCESS,
            data: output,
          });
          resolve();
        })
        .catch((error) => {
          dispatch({
            type: REWARD_POOLS_FETCH_FAILURE,
          });
          return reject(error.message || error);
        });
  });

    return promise;
  };
}

export function useFetchRewardPoolsDetails() {
  const dispatch = useDispatch();

  const { rewardPoolsDetails, fetchRewardPoolsDetailsPending, fetchRewardPoolsDetailsDone } =
    useSelector(
      (state) => ({
        rewardPoolsDetails: state.home.rewardPoolsDetails,
        fetchRewardPoolsDetailsPending: state.home.fetchRewardPoolsDetailsPending,
        fetchRewardPoolsDetailsDone: state.home.fetchRewardPoolsDetailsDone,
      }),
      shallowEqual
    );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchRewardPoolsDetails(data));
    },
    [dispatch]
  );

  return {
    rewardPoolsDetails,
    fetchRewardPoolsDetails: boundAction,
    fetchRewardPoolsDetailsDone,
    fetchRewardPoolsDetailsPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case REWARD_POOLS_FETCH_BEGIN:
      return {
        ...state,
        fetchRewardPoolsDetailsPending: true,
      };

    case REWARD_POOLS_FETCH_SUCCESS:
      return {
        ...state,
        rewardPoolsDetails: action.data ? action.data : 0,
        fetchRewardPoolsDetailsDone: true,
        fetchRewardPoolsDetailsPending: false,
      };

    case REWARD_POOLS_FETCH_FAILURE:
      return {
        ...state,
        fetchRewardPoolsDetailsPending: false,
      };

    default:
      return state;
  }
}
