export const FETCH_REWARDS_DETAIL_BEGIN = "FETCH_REWARDS_DETAIL_BEGIN";
export const FETCH_REWARDS_DETAIL_SUCCESS = "FETCH_REWARDS_DETAIL_SUCCESS";
export const FETCH_REWARDS_DETAIL_FAILURE = "FETCH_REWARDS_DETAIL_FAILURE";

export const FETCH_CLAIM_REWARD_BEGIN = "FETCH_CLAIM_REWARD_BEGIN";
export const FETCH_CLAIM_REWARD_SUCCESS = "FETCH_CLAIM_REWARD_SUCCESS";
export const FETCH_CLAIM_REWARD_FAILURE = "FETCH_CLAIM_REWARD_FAILURE";

export const FETCH_CLAIM_ALL_BEGIN = "FETCH_CLAIM_ALL_BEGIN";
export const FETCH_CLAIM_ALL_SUCCESS = "FETCH_CLAIM_ALL_SUCCESS";
export const FETCH_CLAIM_ALL_FAILURE = "FETCH_CLAIM_ALL_FAILURE";

export const FETCH_ESCROW_DETAIL_BEGIN = "FETCH_ESCROW_DETAIL_BEGIN";
export const FETCH_ESCROW_DETAIL_SUCCESS = "FETCH_ESCROW_DETAIL_SUCCESS";
export const FETCH_ESCROW_DETAIL_FAILURE = "FETCH_ESCROW_DETAIL_FAILURE";