export const tokens = {
  basicRewardAddress: "0x436da116249044e8b4464f0cf21dd93311d88190",
  rewardTokens: [
    {
      token: "ZEUM",
      address: "0x436da116249044e8b4464f0cf21dd93311d88190",
      decimals: 18,
      coingeckoId: "colizeum",
      rewardPerSecond: 482253086419753000
    }
  ]
};