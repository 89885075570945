export const contracts = {
  multicall: {
    address: "0x29de9dfa4adfe50c878fb2ffff8c1b28d9e24355",
  },
  escrowedRewards: [
    {
      tokenName: "ZEUM",
      tokenAddress: "0x436dA116249044E8B4464F0Cf21Dd93311d88190",
      escrowPool: "0x5902cE260FcC0a6f1be090571d4772D1A1FDAefc",
      liquidityMiningManager: "0x16A87EFA00e8B89AC439894435337DeBfae5B634",
      view: "0x26B3d5061a012CA0B6c71c194785097600997253"
    }
  ],
  singleTokenPool: {
    address: "0xf77De3569a5d77ba86f4dBf5F9a39e4112a6bC5D",
  },
  lpPool: {
    address: "0x916a371dAAB48BbF537EE76d418a3D4b2Aa9Fb22",
  },
  uniswapLpPairAddress : {
    address: "0x0dfe1eB120Ce8C43671BF9F4C51C27583Ca72cD2"
  }
};
