export const pools = [
  {
    pid: 0,
    stakedTokenName: "ZEUM",
    stakedTokenSymbol: "ZEUM",
    rewardTokenName:"ZEUM",
    stakedTokenDecimals: 18,
    tokenAddress: "0x436dA116249044E8B4464F0Cf21Dd93311d88190",
    poolAddress: "0xf77De3569a5d77ba86f4dBf5F9a39e4112a6bC5D",
    getUrl:"https://app.uniswap.org/#/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0x436da116249044e8b4464f0cf21dd93311d88190&use=V2&chain=mainnet",
    weight: 0.2,
    toFixed: 2
  },
  {
    pid: 1,
    stakedTokenName: "ZEUMLP",
    stakedTokenSymbol: "ZEUMLP",
    rewardTokenName:"ZEUM",
    stakedTokenDecimals: 18,
    tokenAddress: "0x0dfe1eB120Ce8C43671BF9F4C51C27583Ca72cD2",
    poolAddress: "0x916a371dAAB48BbF537EE76d418a3D4b2Aa9Fb22",
    getUrl:"https://app.uniswap.org/#/add/v2/0x436da116249044e8b4464f0cf21dd93311d88190/0xdac17f958d2ee523a2206206994597c13d831ec7?chain=mainnet",
    weight: 0.8,
    toFixed: 6
  }
];
