import React, { memo, useState, useEffect } from 'react';
import { useFetchPrice } from '../../price/fetchPrice';
import { useConnectWallet } from 'features/home/redux/connectWallet';
import logo from "../../../assets/img/coilzeum_logo.png";
import { addToken } from "./addTokens"
import { tokens } from 'features/configure';


const TokenPrice = () => {
  const { web3 } = useConnectWallet();

  const { fetchPrice, priceDatas } = useFetchPrice();
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const price = priceDatas ? priceDatas[tokens.basicRewardAddress] : 0;
    setPrice(price);
  }, [priceDatas]);

  useEffect(() => {
    if (web3) {
      fetchPrice({ web3 });

      const id = setInterval(() => {
        fetchPrice({ web3 });
      }, 10000);
      return () => clearInterval(id);
    }
  }, [web3]);

  return (
    <>
      <img
        alt="ERP"
        src={logo}
        onClick={addToken}
        style={{ height: '20px', marginRight: '5px', marginLeft: '50px', cursor: 'pointer'}}
      />
      <span style={{ fontWeight: 800, color: "white" }}>${price ? price.toFixed(2) : 'Loading'}</span>
    </> 
  );
};

export default memo(TokenPrice);
